import { PaginationState } from '@tanstack/react-table'

export const CALLBACK_URL_KEY = 'callbackUrl' as const
export const FEEDBACK = '/feedback'
export const DROPDOWN_OPEN_ITEMS_LIMIT = 3
export const APP_VERSION_HEADER_KEY = 'x-client-app-version'
export const REQUIRE_UPDATE_EVENT = 'require-update'
export const DEFAULT_PAGINATION: PaginationState = {
  pageIndex: 0,
  pageSize: 8,
}
export const MOBILE_NAVBAR_HEIGHT = '4rem'
export const ALERT_INFOBOX_HEIGHT = '3rem'
export const MAX_DRAWER_WIDTH = '530px'

export const MAX_RETRIES = 3
